<script lang="ts" setup>
import { Field, useField } from 'vee-validate';
import { ref } from 'vue';

const { value, errorMessage } = useField<string>('memberNumber');
const focused = ref(false);
</script>

<template>
  <div class="form-row">
    <div class="col">
      <div
        class="field"
        :class="{
          changed: value,
          'is-focused': focused,
          'is-invalid': errorMessage,
        }"
      >
        <Field
          type="text"
          name="memberNumber"
          id="memberNumber"
          :class="{ 'is-invalid': errorMessage }"
          maxlength="16"
          @focus="focused = true"
          @blur="focused = false"
          @input="
            value =
              '308147' +
              value.replace(/[^\d]*/g, '').replace(/308147|30814/, '')
          "
        />
        <label for="memberNumber">* AerClub Membership Number</label>
        <div class="invalid-feedback">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
