import axios from 'axios';
import { useConfig } from '@/config';

// create axios instances for configured API endpoint
export const useAxiosInstance = () => {
  const axiosInstance = axios.create();
  const config = useConfig();

  // set base url
  axiosInstance.defaults.baseURL = config.apiEndpoint;

  return {
    axios: axiosInstance,
  };
};
