import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { ClaimFormData } from '@/interfaces';

export interface ClaimFormState extends ClaimFormData {
  entryId: number;
}

export const useClaimFormStore = defineStore('claimForm', () => {
  const formData = ref<ClaimFormState>();

  const setFormData = (data: ClaimFormState) => {
    formData.value = data;
  };

  return { formData, setFormData };
});
