<script setup lang="ts">
import { ref } from 'vue';
import { Form, Field, type SubmissionHandler } from 'vee-validate';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';
import { useClaimFormStore } from '@/stores/useClaimFormStore';
import { useClaimFormApi } from '@/apis';
import DatePicker from './DatePicker.vue';
import MemberNumberField from './fields/MemberNumberField.vue';
import type { ClaimFormData } from '@/interfaces';
import FlightNumberField from './fields/FlightNumberField.vue';
import { validationSchema } from 'shared';
// shared/index.ts

const claimFormStore = useClaimFormStore();
const router = useRouter();

const formValues = {
  memberNumber: claimFormStore.formData?.memberNumber
    ? claimFormStore.formData?.memberNumber
    : '308147',
  firstName: claimFormStore.formData?.firstName || '',
  familyName: claimFormStore.formData?.familyName || '',
};

const memberNumberPrefix = '308147';

setTimeout(() => {
  if (window.location.hash === '#claimForm') {
    document.getElementById('claimForm')?.scrollIntoView();
  }
}, 100);

const schema = validationSchema;

const { submitFormData } = useClaimFormApi();
const isSubmitting = ref(false);
const error = ref(null);

const onSubmit = (async (values: ClaimFormData, actions) => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  error.value = null;

  const formData = {
    ...values,
  };

  if (values.flightOrigin) {
    formData.flightOrigin = values.flightOrigin.toUpperCase();
  }

  if (values.flightDestination) {
    formData.flightDestination = values.flightDestination.toUpperCase();
  }

  try {
    const response = await submitFormData(formData);
    claimFormStore.setFormData({ ...formData, entryId: response.id });
    router.push('/confirmation');
  } catch (e: any) {
    if (e?.response?.data?.message === 'Invalid data') {
      actions.setErrors(e?.response?.data?.details);
    } else {
      error.value = e?.response?.data?.message || e.message;
    }
  }

  isSubmitting.value = false;
}) as SubmissionHandler<Record<string, unknown>, unknown>;


</script>

<template>
  <div class="wrapper">
    <Form
      @submit="onSubmit"
      :validation-schema="schema"
      :initial-values="formValues"
      v-slot="{ errors, values, meta }"
      id="claimForm"
      autocomplete="off"
    >
      <fieldset>
        <div class="row">
          <div>
            <div>
              <div>
                <h3>Late Claim Form</h3>
                <p>
                  All necessary information can be found on your Booking
                  Confirmation email or on your ticket.
                </p>
              </div>
            </div>

            <div class="error-message" v-if="error" role="alert">
              <span role="presentation" class="uil-icons uil-icon-alarm"></span>
              <span class="message"
                >We were unable to process your request. Please try again
                later.</span
              >
            </div>

            <MemberNumberField />

            <div class="form-row">
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.firstName,
                    'is-invalid': errors.firstName,
                  }"
                >
                  <Field
                    type="text"
                    name="firstName"
                    id="firstName"
                    :class="{ 'is-invalid': errors.firstName }"
                  />
                  <label for="firstName">First Name</label>
                  <div class="invalid-feedback">
                    {{ errors.firstName }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.familyName,
                    'is-invalid': errors.familyName,
                  }"
                >
                  <Field
                    type="text"
                    name="familyName"
                    id="familyName"
                    :class="{ 'is-invalid': errors.familyName }"
                  />
                  <label for="familyName">Family Name</label>
                  <div class="invalid-feedback">
                    {{ errors.familyName }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.departureDate,
                    'is-invalid': errors.departureDate,
                  }"
                >
                  <DatePicker name="departureDate" />
                  <label for="departureDate">Departure Date</label>
                  <div class="invalid-feedback">
                    {{ errors.departureDate }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.airline,
                    'is-invalid': errors.airline,
                  }"
                >
                  <Field
                    name="airline"
                    as="select"
                    :class="{ 'is-invalid': errors.airline }"
                  >
                    <option value="1">Aer Lingus</option>
                    <option value="3">British Airways</option>
                    <option value="4">United Airlines</option>
                  </Field>
                  <label>Airline</label>

                  <div class="invalid-feedback">
                    {{ errors.airline }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.ticketNumber || values.ticketNumberPrefix,
                    'is-invalid': errors.ticketNumber,
                  }"
                >
                  <Field
                    type="text"
                    name="ticketNumber"
                    id="ticketNumber"
                    :class="{ 'is-invalid': errors.ticketNumber }"
                    onkeyup="this.value=this.value.replace(/[^\d]*/g,'')"
                  />
                  <label for="ticketNumber">Ticket Number</label>
                  <div class="invalid-feedback">
                    {{ errors.ticketNumber }}
                  </div>
                </div>
              </div>

              <FlightNumberField />
            </div>

            <div
              class="form-row"
              v-if="values.airline === '3' || values.airline === '4'"
            >
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.flightOrigin,
                    'is-invalid': errors.flightOrigin,
                  }"
                >
                  <Field
                    type="text"
                    name="flightOrigin"
                    id="flightOrigin"
                    :class="{ 'is-invalid': errors.flightOrigin }"
                  />
                  <label for="flightOrigin">Origin</label>
                  <div class="invalid-feedback">
                    {{ errors.flightOrigin }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.flightDestination,
                    'is-invalid': errors.flightDestination,
                  }"
                >
                  <Field
                    type="text"
                    name="flightDestination"
                    id="flightDestination"
                    :class="{ 'is-invalid': errors.flightDestination }"
                  />
                  <label for="flightDestination">Destination</label>
                  <div class="invalid-feedback">
                    {{ errors.flightDestination }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-row"
              v-if="values.airline === '3' || values.airline === '4'"
            >
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.bookingClass,
                    'is-invalid': errors.bookingClass,
                  }"
                >
                  <Field
                    name="bookingClass"
                    as="select"
                    :class="{ 'is-invalid': errors.bookingClass }"
                  >
                    <option value="" disabled></option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="V">V</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                  </Field>
                  <label>Booking Class</label>
                  <div class="invalid-feedback">
                    {{ errors.bookingClass }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="field"
                  :class="{
                    changed: values.cabinClass,
                    'is-invalid': errors.cabinClass,
                  }"
                >
                  <Field
                    name="cabinClass"
                    as="select"
                    :class="{ 'is-invalid': errors.cabinClass }"
                  >
                    <option value="" disabled></option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                    <option value="H">H</option>
                    <option value="I">I</option>
                    <option value="J">J</option>
                    <option value="K">K</option>
                    <option value="L">L</option>
                    <option value="M">M</option>
                    <option value="N">N</option>
                    <option value="O">O</option>
                    <option value="P">P</option>
                    <option value="Q">Q</option>
                    <option value="R">R</option>
                    <option value="S">S</option>
                    <option value="T">T</option>
                    <option value="V">V</option>
                    <option value="X">X</option>
                    <option value="Y">Y</option>
                  </Field>
                  <label>Cabin Class</label>
                  <div class="invalid-feedback">
                    {{ errors.cabinClass }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="submit">
        <button
          type="submit"
          :class="{ disabled: !meta.valid || isSubmitting }"
        >
          Submit Claim
        </button>
      </div>
    </Form>
  </div>
</template>

<style>
/**
 * form structure
 */
form {
  background: var(--cloud-white);
  border-radius: 8px;
  padding: 32px;
}

@media (max-width: 768px) {
  form {
    padding: 20px;
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

div.submit {
  text-align: right;
}

div.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
}

@media (max-width: 768px) {
  div.form-row {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
div.form-row.large {
  grid-template-columns: 1fr;
}

div.form-row div.field {
  position: relative;
}

div.form-row div.field.is-invalid:after {
  color: var(--error);
  content: '!';
  font-size: 23px;
  font-weight: bolder;
  position: absolute;
  right: 20px;
  top: 15px;
}

div.form-row div.field.is-invalid:after:active {
  content: '' !important;
}

div.form-row div.field label {
  position: absolute;
  top: 25px;
  left: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

/**
 * shift and prefix
 */

div.form-row div.field.shift input {
  max-width: calc(100% - 65px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

div.form-row div.field.shift input ~ label {
  left: 80px;
  white-space: pre;
}

div.form-row div.field.shift select {
  max-width: 90px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

div.form-row div.field.changed label,
div.form-row div.field.shift.changed label,
div.form-row div.field input:focus ~ label,
div.form-row div.field select:focus ~ label {
  top: 5px;
  font-size: 0.7rem;
}

div.form-row div.field input[type='date']:focus ~ label {
  top: 20px;
  left: 5px;
}

/**
 * for elements
 */

input {
  border: 1px solid #d0d0ce;
  border-radius: 4px;
  background: #ffffff;
  font-size: 16px;
  padding: 15px 10px 3px 10px;
  height: 53px;
  width: 100%;
}

input:focus,
div.form-row div.field.is-focused span.inputPrefix {
  border-color: var(--teal);
}
input:focus ~ label,
select:focus ~ label {
  color: var(--teal);
}

input.is-invalid {
  border-color: var(--error);
  color: var(--error);
}

input.is-invalid ~ label {
  color: var(--error);
}

input[type='date'] {
  padding-left: 150px;
  width: 100%;
}

span.inputPrefix {
  display: inline-block;
  border-left: 1px solid #d0d0ce;
  border-top: 1px solid #d0d0ce;
  border-bottom: 1px solid #d0d0ce;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: white;
  font-size: 16px;
  padding: 15px 0 5px 10px;
  min-width: 65px;
  height: 53px;
  line-height: 37px;
  vertical-align: bottom;
  text-align: right;
}

div.form-row div.field.is-invalid span.inputPrefix {
  border-color: var(--error);
  color: var(--error);
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  text-indent: 5px;
  height: 53px;
  width: 100%;
  padding: 15px 10px 2px 10px;
  border: 1px solid #d0d0ce;
  border-radius: 4px;
  margin: 0;
  outline: 0;
  background: white url('/images/select-arrow-down.svg') no-repeat right bottom;
  background-size: 35px 35px;
  font-family: 'ITCAvantGardeStd-Book', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: #4a4a4a;
}

select.is-invalid {
  background-image: none;
}

/**
 * errrors
 */

div.invalid-feedback {
  color: var(--error);
  padding: 10px 0;
  font-size: 0.875rem;
}

div.error-message .uil-icons {
  color: var(--error);
  font-size: 2em;
  margin-right: 20px;
}

div.error-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  vertical-align: middle;
  border: var(--error) 1px solid;
  background-color: var(--error5);
  padding: 16px;
  margin: 32px 0;
  border-radius: 8px;
}

#flightOrigin,
#flightDestination {
  text-transform: uppercase;
}
</style>
