<script setup lang="ts">
import { ref, watch } from 'vue';
import { useField } from 'vee-validate';
import { DatePicker } from 'v-calendar';
import { format } from 'date-fns';

const props = defineProps({
  name: { type: String, required: true },
});

// today minus 6 months
const minDate = new Date();
minDate.setMonth(minDate.getMonth() - 6);

const { handleChange, handleBlur } = useField(props.name);

const date = ref();
watch(date, () => {
  handleChange(format(date.value, 'yyyy-MM-dd'));
});

const onBlur = () => {
  handleChange(format(date.value, 'yyyy-MM-dd'));
  handleBlur();
};
</script>

<template>
  <DatePicker
    v-model="date"
    :masks="{ input: 'DD MMM YYYY' }"
    :max-date="new Date()"
    :min-date="minDate"
    :update-on-input="false"
    @input="handleChange($event, false)"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :id="props.name"
        type="text"
        :value="inputValue"
        v-on="inputEvents"
        @blur="onBlur"
      />
    </template>
  </DatePicker>
</template>

<style>
.vc-light .vc-attr {
  --vc-highlight-solid-bg: #006272 !important;
}

.vc-pane-container button {
  background: none;
  
}
.vc-pane-container button:hover {
  box-shadow: none;
  transform: none;
}
</style>
