import { inject, type App } from 'vue';

export interface AppConfig {
  apiEndpoint: string;
  baseUrl: string;
}

export const configPlugin = {
  install: (app: App, config: AppConfig) => {
    app.provide('config', config);
    app.config.globalProperties.$config = config;
  },
};

export function useConfig(): AppConfig {
  return inject('config') as AppConfig;
}
