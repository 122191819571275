<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <div class="banner">
      <div class="image"><img src="/images/aerclub-logo.svg" /></div>
      <p>
        Don’t forget - Avios and Tier Credits are automatically credited to your
        account if you include your AerClub number at the time of booking.
      </p>
    </div>
  </div>
</template>

<style scoped>
div.banner {
  display: flex;
  flex-direction: row;
  gap: 32px;
  background: var(--black);
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  div.banner {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
}
div.banner p {
  margin: 0;
  color: white;
}

img {
  max-width: 237px;
}
</style>
