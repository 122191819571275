<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <p>
      Please complete the form below to claim Avios and Tier Credits for flights
      flown in the last 6 months.
    </p>
  </div>
</template>

<style scoped></style>
