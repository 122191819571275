import { createApp } from 'vue';
import { createPinia } from 'pinia';
import SetupCalendar from 'v-calendar';

import App from './App.vue';
import createRouter from './router';
import { configPlugin, type AppConfig } from './config';

import 'v-calendar/dist/style.css';
import './assets/main.css';

// custom init function for passing common config values
window.initAerClubApp = (config: AppConfig) => {
  const app = createApp(App);

  app.use(configPlugin, config);
  app.use(createPinia());
  app.use(SetupCalendar, {});

  const router = createRouter(config.baseUrl);
  app.use(router);

  router.isReady().then(() => {
    app.mount('#app');
  });
};

// trigger loaded event - let the listeners know that the init function is available
window.dispatchEvent(new Event('aerclub-app-loaded'));
