import { createRouter as vueCreateRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import ConfirmationView from './views/ConfirmationView.vue';

const createRouter = (baseUrl: string) => {
  return vueCreateRouter({
    history: createWebHistory(baseUrl),
    routes: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/confirmation',
        name: 'confirmation',
        component: ConfirmationView,
      },
    ],
  });
};

export default createRouter;
