<script lang="ts" setup>
import { Field, useField } from 'vee-validate';
import { computed, watch } from 'vue';

const { value, errorMessage } = useField<string>('flightNumber');
const { value: airlineValue } = useField<string>('airline');

const prefix = computed(() => {
  if (airlineValue.value === '1') return 'EI';
  if (airlineValue.value === '3') return 'BA';
  return 'UA';
});

watch(airlineValue, () => {
  if (!value.value || value.value.length === 2) {
    value.value = prefix.value;
    return;
  }

  value.value = prefix.value + value.value?.replace(/[^\d]*/g, '');
});
</script>

<template>
  <div class="col">
    <div
      class="field"
      :class="{
        changed: value,
        'is-invalid': errorMessage,
      }"
    >
      <Field
        type="text"
        name="flightNumber"
        id="flightNumber"
        :class="{ 'is-invalid': errorMessage }"
        @input="value = prefix + value.replace(/[^\d]*/g, '')"
        maxlength="6"
      />
      <label for="flightNumber">Flight Number</label>
      <div class="invalid-feedback">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
