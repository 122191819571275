<script setup lang="ts"></script>

<template>
  <div class="wrapper">
    <h4>Notes:</h4>

    <ul>
      <li>
        Each member must complete an individual claim for each flight segment of
        their journey e.g. A return flight requires 2 claim forms to be
        submitted.
      </li>
      <li>
        The first name and surname on the booking must be the same as the first
        name and surname in your AerClub profile to be eligible for award. If
        it’s not, you can find contact details to request a change to your
        AerClub profile
        <a
          target="_blank"
          href="https://www.aerlingus.com/support/customer-care/requests-and-enquiries/upcoming-travel-enquiry/"
          >here</a
        >.
      </li>
      <li>
        Applicable Avios or Tier Credits will only be awarded to your individual
        AerClub account after you have flown. You cannot claim for another
        passengers flight.
      </li>
      <li>
        Claims can be made for Aer Lingus or AerClub partner ticketed flights
        that have been flown within the last 6 months. Flights flown more than 6
        months ago are no longer eligible for award.
      </li>
      <li>
        Aer Lingus ticketed and operated flights that have been flown up to 3
        months prior to registering for AerClub are not eligible for Tier
        Credits but may be eligible for Avios.
      </li>
      <li>
        Avios award on AerClub partners flights may take up to 6-10 weeks to
        credit to your account
      </li>
      <li>
        If you want to claim for missing Avios from AerClub non-airline partners
        e.g. AerClub eStore, please go to the missing Avios section  
        <a
          target="_blank"
          href="https://estore.aerlingus.com/en-IE?_gl=1*qnrfzh*_gcl_au*MTg2MzYyMDk5Ni4xNzA3OTA2NDAy&_ga=2.210752925.987256279.1710426706-1267363753.1700054951#utm_source=aerlingus.com&utm_medium=Referal&utm_campaign=AerClub_Tab"
          >here</a
        >
        
      </li>
      <li>
        To claim for flights with another loyalty programme, please contact that
        programme directly. AerClub cannot process these claims.
      </li>
    </ul>
  </div>
</template>

<style scoped>
div.wrapper {
  margin-top: 32px;
}
</style>
