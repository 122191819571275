import type { ClaimFormData } from '@/interfaces';
import { useAxiosInstance } from './useAxiosInstance';

export interface ClaimFormResponse {
  id: number;
}

export const useClaimFormApi = () => {
  const { axios } = useAxiosInstance();

  const submitFormData = async (formData: ClaimFormData) =>
    axios
      .post<ClaimFormResponse>('/claim-form', formData)
      .then((res) => res.data);

  return {
    submitFormData,
  };
};
